import { useStorage } from '@vueuse/core';
import ROUTES from '~/assets/javascript/constants/routes';
import { firstItemIdInRecursiveList, isIdPresentInRecursiveList } from '~/assets/javascript/utils/array';
import type { WorkspaceAppCategory } from '~/types';

const onboardingStorage = useStorage('zazos-apps-onboarding', {
  currentOnboardingKey: undefined,
} as {
  currentOnboardingKey: string | undefined;
});

function isViewIdPresentInCategory(category, viewId) {
  return isIdPresentInRecursiveList(category.views, viewId)
    || isIdPresentInRecursiveList(category.post_installation_setup_views, viewId)
    || isIdPresentInRecursiveList(category.hidden_views, viewId);
}

export function useStartAppOnboarding() {
  const { $store, $router, $localeRoute } = useNuxtApp();
  const categories = computed<WorkspaceAppCategory[]>(() => $store.getters['workspace/categoryHierarchy']);

  return (categoryId, tenantSlug) => {
    const category = categories.value.find(category => category.id === categoryId)!;
    const viewId = firstItemIdInRecursiveList(category.post_installation_setup_views)
      || firstItemIdInRecursiveList(category.views);

    onboardingStorage.value.currentOnboardingKey = categoryId;

    if (!viewId) {
      $router.push($localeRoute({ name: ROUTES.member.views.routeName, params: { tenantSlug } }));
    } else {
      $router.push($localeRoute({ name: ROUTES.member.view.routeName, params: { tenantSlug, viewId } }));
    }
  };
}

export function useAppOnboardingMiddleware() {
  const { $store, $localeRoute } = useNuxtApp();
  const categories = computed<WorkspaceAppCategory[]>(() => $store.getters['workspace/categoryHierarchy']);
  const category = computed<WorkspaceAppCategory>(() => categories.value.find(
    category => category.id === onboardingStorage.value.currentOnboardingKey,
  )!);

  const finishOnboarding = () => {
    onboardingStorage.value.currentOnboardingKey = undefined;

    return undefined;
  };

  const categoriesSequence = computed(
    () => category.value.onboarding_path?.map(
      app => categories.value.find(category => category.id === app)!,
    )?.filter(Boolean) || [],
  );

  const hasOnboarding = computed(() => category.value && !_isEmpty(category.value?.onboarding_path));
  const onboardingDone = computed(() => categoriesSequence.value.every(app => app.onboarding_done));

  function viewIsFromSomeCategoryOfOnboarding(viewId) {
    return categoriesSequence.value.some(category => isViewIdPresentInCategory(category, viewId));
  }

  function viewIsFromCategoryPostInstallationSetup(category: WorkspaceAppCategory, viewId: string) {
    return isIdPresentInRecursiveList(category.post_installation_setup_views, viewId);
  }

  function loadWorkspace() {
    return $store.dispatch('workspace/loadWorkspace', { skipNextCall: true, waitLoad: true });
  }

  const nextCategory = computed(() => categoriesSequence.value.find(category => !category.onboarding_done));

  function buildViewRoute(category: WorkspaceAppCategory, currentRoute) {
    const firstSetupViewId = firstItemIdInRecursiveList(category.post_installation_setup_views);

    if (!firstSetupViewId) return undefined;

    return $localeRoute({
      name: ROUTES.member.view.routeName,
      params: {
        tenantSlug: currentRoute.params.tenantSlug,
        [ROUTES.member.view.viewId]: firstSetupViewId,
      },
    });
  }

  return {
    buildViewRoute,
    finishOnboarding,
    hasOnboarding,
    loadWorkspace,
    nextCategory,
    onboardingDone,
    viewIsFromSomeCategoryOfOnboarding,
    viewIsFromCategoryPostInstallationSetup,
  };
}
